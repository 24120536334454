export const entrepreneurshipHighlights = [
  {
    id: 1,
    img: "SWAP_LOGO_IMG",
    alt: "Swap Logo",
    title: "Founder, Swap",
    subtitle: "https://healthyfoodswap.com",
    link: "https://healthyfoodswap.com",
    time: "Jan 2025 – present",
    text:
      '<p>Snap a photo of your meal and instantly discover healthier alternatives.<ul style="list-style-type:none"><li>✔️ Instant food recognition.</li><li>✔️ Nutrition analysis.</li><li>✔️ Healthier food alternatives for healthier living.</li><li>✔️ Available on <a href="https://apps.apple.com/app/swap-smart-food-choices/id6744303617">Apple App Store</a> and on <a href="https://play.google.com/store/apps/details?id=com.mms.app.swap">Google Play</a>.</li></ul></p>',
  },
  {
    id: 2,
    img: "QWISELY_LOGO_IMG",
    alt: "Qwisely Logo",
    title: "Co-Founder, Qwisely",
    time: "June 2020 – May 2023",
    text:
      '<p>Curbside check-in and online COVID-19 prescreening solution for dental offices.<ul style="list-style-type:none"><li>✔️ Used by over 50,000 patients across Ontario.</li><li>✔️ 90% savings in check-in time along with 2 less phone calls/patient.</li></ul></p>',
  },
  {
    id: 3,
    img: "CATALYTICSINC_LOGO_IMG",
    alt: "Catalytics Inc Logo",
    title: "Co-Founder, Catalytics Inc",
    time: "Mar 2017 – Dec 2018",
    text:
      '<p>Predictive analytics for intelligent medical decision-making.<ul style="list-style-type:none"><li>✔️ Winner of The Forge @ Mac Startup Competition Grand Prize – $20,000 cash prize.</li><li>✔️ First place, Hack the City – Health Stream.</li><li>✔️ Exhibitor at Health Achieve Conference, Apps4Health Conference, and Emerging Leader Forum.</li><li>✔️ Presenter at Achieving Excellence Together Conference.</li><li>✔️ <a href="https://journal.stemfellowship.org/doi/pdf/10.17975/sfj-2018-003">Journal publication of our research. 🔗</a></li></ul></p>',
  },
  {
    id: 4,
    img: "WEBSITESENSEI_LOGO_IMG",
    alt: "Website Sensei Logo",
    title: "Website Sensei",
    subtitle: "https://websitesensei.com",
    link: "https://websitesensei.com",
    time: "Dec 2019 – present",
    text:
      '<p>All things website at <a href="https://websitesensei.com">websitesensei.com</a><ul style="list-style-type:none"><li>✔️ Over a decade of web development experience.</li><li>✔️ Millions of total page views.</li><li>✔️ Dozens of websites.</li><li>✔️ Helped Toronto mainstreet businesses get online during pandemic.</li></ul></p>',
  },
];
